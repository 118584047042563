@import "../consts";

.modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 110;
  padding-top: 0;
  padding-bottom: 70px;
  backface-visibility: hidden;
  @include transition('background');

  &.-visible {
    background: rgba($color-black, 0.2);
  }

  &-window {
    min-width: 500px;
    padding: 0;
    width: auto;
    max-height: 100%;
    background: $color-white;
    border-radius: 0 0 $br $br;
    display: flex;
    align-items: stretch;
    position: relative;
    transform: translate3d(0, -50px, 0);
    box-shadow: 0 0 0 0 rgba($color-black, 0.1);
    opacity: 0;
    visibility: hidden;
    @include transition('transform, opacity, visibility, box-shadow');
  }

  &-area {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
  }

  &-column {
    display: flex;
    position: relative;
    flex-direction: column;

    & + & {
      border-left: 1px solid $color-separator;
    }
  }

  &.-visible > &-window {
    transform: none;
    opacity: 1;
    visibility: visible;
    box-shadow: 0 15px 15px -5px rgba($color-black, 0.1);
  }

  &-header,
  &-footer {
    padding: 6px 0 15px 20px;
    position: absolute;
    left: 0;
    width: 100%;
    background: $color-white;
    z-index: 20;

    > * {
      margin-right: 20px;
      margin-top: 9px;
    }
  }

  &-header {
    @include headline(1);
    top: 0;
    z-index: 120;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &.-close {
      padding-right: 50px;
    }

    > hr {
      flex-basis: 100%;
      height: 2px;
      margin-top: -2px;
      opacity: 0;
      visibility: hidden;
      min-height: unset;
    }

    &-buttons {
      display: flex;
      flex-grow: 1;

      > .button {
        margin-right: 15px;
      }
    }
  }

  &-footer {
    bottom: 0;
    display: flex;
    justify-content: flex-end;

    > .button {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-body {
    flex-grow: 1;
    position: relative;
    padding: 16px 20px 16px 20px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    outline: none;
  }

  &-close {
    @include clear();
    width: 36px;
    height: 36px;
    position: absolute;
    fill: $color-secondary;
    stroke: $color-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $br;
    cursor: pointer;
    right: 10px;
    top: 14px;

    &:hover {
      fill: $color-primary;
      stroke: $color-primary;
    }
  }

  &-title {
    @include headline(2);
    margin-top: -2px;
    margin-bottom: 10px;
  }

  &-aside-wrapper {
    flex-basis: 100%;
    flex-grow: 1;
    margin: 0 -16px;
  }

  &-aside {
    padding: 0 16px;
  }
}

@supports (backdrop-filter: blur(10px)) {
  .modal {
    &-header,
    &-footer {
      background: rgba($color-white, 0.8);
      backdrop-filter: blur(10px);
    }
  }
}

