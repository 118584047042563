@import "../../consts";

.textbox {
  display: inline-flex;
  fill: $color-primary;
  stroke: $color-primary;
  border-radius: $br;
  position: relative;
  align-items: center;

  &.-wide {
    width: 100%;
  }

  > .icon {
    min-width: 24px;
    position: relative;
    z-index: 1;
    margin: 0 -8px 0 6px;
    fill: $color-secondary;
    stroke: $color-secondary;
    order: 1;
  }

  &.-small > .icon {
    min-width: 20px;
    height: 20px;
    margin: 1px 0 0 5px;
  }

  &-control {
    @include clear();
    @include text-control();
    width: 100%;
    flex-grow: 1;
    height: 34px;
    position: relative;
    margin-bottom: 2px;
    z-index: 1;
    order: 2;
    padding: 0 14px;
    color: $color-black;

    &::placeholder {
      color: $color-secondary;
    }

    &:disabled {
      color: $color-secondary;

      &::placeholder {
        color: $color-tertiary;
      }
    }

    &[aria-invalid] {
      color: change-color($color-danger, $lightness: 35%);

      &::placeholder {
        color: change-color($color-danger, $lightness: 65%, $saturation: 60%);
      }
    }

    &.-select {
      cursor: pointer;
      margin-right: -21px;
      padding-right: 21px;
      flex-basis: 200%;

      &.-opened {
        cursor: text;
      }

      &:disabled {
        cursor: default;
      }
    }
  }

  &-control:disabled ~ .icon {
    fill: $color-tertiary;
    stroke: $color-tertiary;
  }

  &.-small > &-control {
    @include text-body();
    height: 26px;
    padding: 1px 6px 0;
  }

  &.-filter > &-control {
    @include text-body();
  }

  &.-center > &-control {
    text-align: center;
  }

  &.-headline > &-control {
    @include headline(1);
    margin: 1px 0;
  }

  &-appearance {
    @include transition('box-shadow, background', short);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $br;
    box-shadow: inset 0 0 0 1px $color-quarternary;
  }

  &-control:focus ~ &-appearance {
    box-shadow: inset 0 0 0 1px $color-tertiary;
    background: rgba($color-white, 0.7);
  }

  &-control[aria-invalid] ~ &-appearance {
    box-shadow: inset 0 0 0 1px change-color($color-danger, $lightness: 90%, $saturation: 70%);
  }

  &-control[aria-invalid]:focus ~ &-appearance {
    box-shadow: inset 0 0 0 1px change-color($color-danger, $lightness: 80%, $saturation: 70%);
  }

  &-button {
    @include clear();
    position: relative;
    z-index: 1;
    margin-right: 5px;
    margin-left: -10px;
    fill: $color-secondary;
    stroke: $color-secondary;
    color: $color-secondary;
    cursor: pointer;
    order: 3;

    &:hover {
      fill: $color-blue;
      stroke: $color-blue;
    }

    &.-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &.-select {
      pointer-events: none;

      > .icon {
        transform: rotate(-90deg);
        @include transition(transform, short);
      }

      &.-opened {
        z-index: 100;

        > .icon {
          transform: rotate(90deg);
        }
      }
    }
  }

  &-control:disabled ~ &-button {
    opacity: 0.5;
  }

  &.-small > &-button {
    margin-right: 2px;
    margin-top: 1px;
  }
}
