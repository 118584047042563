@import "../consts";

.datum {
  margin: 0;
  padding: 0;
  width: 100%;

  > dt {
    @include text-body(medium);
    color: $color-black;
    fill: $color-primary;
    stroke: $color-primary;
    margin: 0;
    padding: 6px 0 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .button {
      margin-top: -2px;
      margin-bottom: -2px;
    }
  }

  * + & > dt {
    padding-top: 14px;
  }

  .image + &,
  .image-slider + & {
    margin-top: 4px;
  }

  > dd {
    margin: -1px -10px 0;
    padding: 5px 10px;
    display: flex;
    min-height: 30px;
    color: $color-primary;
    fill: $color-secondary;
    // stroke: $color-secondary;
    text-align: left;
    overflow: hidden;
    position: relative;

    .svg-sidebar-mck {
      fill: none;

      ellipse {
        fill: white;
      }
      circle {
        stroke: $color-secondary;
      }
      path {
        fill: $color-secondary;
      }
    }

    .svg-sidebar-mobile {
      fill: none;

      circle {
        fill: $color-secondary;
      }
      path {
        fill: $color-secondary;
        stroke: $color-secondary;
      }
      rect {
        stroke: $color-secondary;
      }
    }

    .svg-trailer {
      fill: none;

      mask {
        fill: white;
      }
      path {
        stroke: $color-secondary;
      }
      circle {
        stroke: $color-secondary;
        fill: #f5f7f7;
      }
      rect {
        stroke: $color-secondary;
      }
    }

    .svg-danger {
      fill: none;

      path {
        fill: #ff3a2f;
      }
    }

    .svg-success {
      fill: none;

      path {
        fill: #32c758;
      }
    }

    &.-selected {
      background-color: $color-button-hover;

      &:after {
        display: none;
      }
    }

    &.-success {
      background-color: rgba($color-success, 0.1);
    }

    &.-danger {
      background-color: rgba($color-danger, 0.08);
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      left: 10px;
      right: 10px;
      background: $color-separator;
    }

    &.-clickable {
      cursor: pointer;
      margin-left: -20px;
      margin-right: -20px;
      padding-right: 20px;
      padding-left: 20px;

      &:after {
        left: 20px;
        right: 20px;
      }

      &:hover {
        background: $color-button-hover;
      }

      &:active {
        background: $color-button-active;
      }
    }

    &.-search {
      padding: 2px 10px 7px;
    }

    &.-search:after {
      display: none;
    }

    &.-header {
      display: flex;
      color: $color-secondary;
      min-height: 25px;
      align-items: flex-end;

      &:after {
        display: none;
      }

      > span {
        flex-basis: 100%;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    > * + * {
      margin-left: 10px;
    }

    &.-block {
      flex-direction: column;
      align-items: flex-start;
      min-height: unset;

      > * + * {
        margin-left: 0;
      }
    }

    &.-empty,
    &.-empty > .datum-cell {
      min-height: 20px;
      color: $color-secondary;
    }
  }

  &.-simple > dd {
    min-height: 30px;

    &:after {
      display: none;
    }
  }

  &-cell {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    min-height: 34px;

    &.-right {
      text-align: right;
      justify-content: flex-end;
    }

    &.-label {
      color: $color-secondary;
    }

    &.-strong {
      font-weight: 500;
    }

    &.-multiline {
      white-space: pre;
    }

    > .icon {
      min-width: 24px;
      fill: $color-secondary;
      // stroke: $color-secondary;
    }

    > .checkbox {
      margin: -2px;
    }

    *.-block > & {
      min-height: unset !important;
      padding-top: 3px;

      &:last-child {
        padding-bottom: 8px;
      }
    }
  }

  &.-simple > dd > &-cell {
    min-height: 28px;
  }
}
