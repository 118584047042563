@import "../consts";

.filter {
  > .button {
    margin-left: -18px;

    &.-small {
      margin-left: -12px;
    }
  }

  > .list-items + .button {
    margin-top: 4px;
  }

  &-date {
    color: $color-primary;
    fill: $color-secondary;
    stroke: $color-secondary;
    margin-bottom: 5px;

    &-controls {
      display: flex;
      position: relative;

      > .icon {
        min-width: 24px;
      }

      > .-input {
        position: relative;
        margin-left: 10px;
        height: 24px;
        white-space: nowrap;

        &:last-child {
          flex-basis: 100%;
        }

        &:not(:last-child) {
          margin-right: 10px;

          &:after {
            content: '—';
            position: absolute;
            left: 100%;
            margin: 4px 0 0 4px;
            color: $color-secondary;
          }
        }

        > .-size {
          letter-spacing: -0.33px;
          visibility: hidden;
        }

        > input {
          @include clear();
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          letter-spacing: -0.33px;

          &::placeholder {
            color: $color-secondary;
          }
        }
      }
    }

    &-popup {
      top: 4px;
      position: relative;
    }
  }
}
