@import "../../consts";

@keyframes loading {
  0%, 80%, 100% {
    opacity: 0.3;
  }
  
  40% {
    opacity: 0.8;
  }
}

.loader {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;

  > span {
    letter-spacing: 0.1em;
    margin-left: 0.2em;
    position: relative;
    animation-name: loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    opacity: 0.3;
    
    &:before {
      display: inline-block;
      transform: scale(1.5, 1.5);
      content: '•';
      position: relative;
      top: -1px;
    }
    
    &:nth-child(2) {
      animation-delay: 0.15s;
    }

    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  }
  
  &.-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.-icon > span {
    letter-spacing: 0;
    margin-left: 2px;
    width: 4px;
    display: inline-block;
    
    &:first-child {
      margin-left: 0;
    }
  }
}