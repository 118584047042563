@import "../../consts";

.delete {
  @include clear();
  width: 16px;
  height: 16px;
  background: $color-quarternary;
  border-radius: 16px;
  position: relative;
  z-index: 1;
  fill: $color-secondary;
  stroke: $color-secondary;
  cursor: pointer;
  @include transition('background, fill, stroke', short);
  
  &:before {
    @include pointer-area(5px, 21px)
  }
  
  &:hover {
    background: $color-danger;
    fill: $color-white;
    stroke: $color-white;
  }

  &:hover ~ * {
    opacity: 0.7;
    @include transition('opacity, filter', short);
    transition-delay: 0.3s;
  }
  
  &:active ~ * {
    filter: blur(1px);
    opacity: 0.4;
    transition-delay: 0s;
  }
  
  > .icon {
    width: 16px;
    height: 16px;
    
    > svg {
      stroke-width: 2;
    }
  }
  
  &.-disabled {
    pointer-events: none;
    background: rgba($color-quarternary, 0.5);
    color: $color-tertiary;
    fill: $color-tertiary;
    stroke: $color-tertiary;
  }
}