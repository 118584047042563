@import "../../consts";

.menu {
  &-link {
    display: block;
    height: 36px;
    padding: 6px;
    margin: 2px 2px 4px;
    fill: $color-white;
    position: relative;
    border-radius: $br;
    fill: $color-blue-secondary;
    @include transition(background);

    &.active {
      background: $color-background-active;
    }
  }

  &-title {
    position: absolute;
    left: 100%;
    top: 50%;
    background: rgba($color-black, 0.8);
    white-space: nowrap;
    color: $color-white;
    padding: 4px 7px;
    border-radius: $br;
    transform: translate(-10px, -50%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 200;
    @include transition("opacity, visibility, transform");
  }

  .navigation.-active &-link:hover > &-title {
    opacity: 1;
    visibility: visible;
    transform: translate(0, -50%);
  }
}

@supports (backdrop-filter: blur(4px)) {
  .menu-title {
    background: rgba($color-black, 0.6);
    backdrop-filter: blur(4px);
  }
}
