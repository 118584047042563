@import "../../consts";

.add {
  @include clear();
  width: 16px;
  height: 16px;
  background: $color-quarternary;
  border-radius: 16px;
  position: relative;
  z-index: 1;
  fill: $color-secondary;
  stroke: $color-secondary;
  cursor: pointer;
  @include transition('background, fill, stroke', short);
  
  &:before {
    @include pointer-area(5px, 21px)
  }
  
  &:hover {
    background: $color-blue;
    fill: $color-white;
    stroke: $color-white;
  }

  > .icon {
    width: 16px;
    height: 16px;
    
    > svg {
      stroke-width: 2;
    }
  }
}