@import "../../ui-kit/consts";

.infoboard-log {
  &-list {
    position: relative;
    margin: 0 -20px;
    min-height: 300px;

    > .message-block {
      position: relative;
      min-height: 187px;

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background: $color-separator;
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  &-date {
    position: relative;
    margin-top: 0;

    > .button {
      margin-right: 10px;
    }

    > .-selector .button-caption{
      min-width: 70px;
      justify-content: center;
      padding-left: 5px;
    }

    > .-selector .icon {
      fill: $color-blue;
      stroke: $color-blue;
    }
  }

  &-timeline {
    flex-basis: 100%;
    margin-top: 20px;

    &.-dragging {
      &,
      * {
        pointer-events: none !important;
      }
    }

    > .-wrapper {
      margin-right: -50px;
      margin-left: -6px;
      height: 48px;
      display: flex;
      position: relative;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      cursor: pointer;
      @include text-body();

      &:before {
        position: absolute;
        bottom: 18px;
        left: -10px;
        width: 20px;
        text-align: center;
        content: '0';
      }

      &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        height: 10px;
        border-left: 1px solid $color-separator;
      }

      > .-time,
      > .-range {
        position: absolute;
        margin-left: -1px;
        bottom: 0;
        height: 46px;
        border-radius: $br;
        box-shadow: 0 0 0 1px $color-white;

        &:before {
          white-space: nowrap;
          pointer-events: none;
          position: absolute;
          content: attr(data-value);
          bottom: 48px;
          background: $color-blue;
          padding: 2px 5px 1px 5px;
          color: $color-white;
          border-radius: 20px;
          transform: translateX(-50%);
          left: 2px;
        }

        > .-drag {
          position: absolute;
          left: 0;
          top: 100%;
          width: 8px;
          height: 8px;
          border: 4px solid transparent;
          border-bottom: 8px solid $color-blue;
          border-top: none;
          margin: 1px 0 0 -3px;
          cursor: ew-resize;

          &:hover {
            border-bottom-color: darken($color-blue, 10%);
          }

          &:before {
            position: absolute;
            top: -4px;
            left: -8px;
            bottom: -10px;
            right: -8px;
            content: '';
          }
        }
      }

      > .-time {
        width: 2px;
        background: $color-blue;
      }

      > .-range {
        border: 2px solid $color-blue;
        background: rgba($color-blue, 0.5);
        bottom: -1px;
        height: 48px;
        cursor: grab;
        margin-right: -1px;

        &.-active {
          pointer-events: none;
        }

        > .-drag {
          margin-top: 2px;
          margin-left: -5px;

          &.-end {
            left: 100%;
            margin-left: -3px;
          }
        }

        > .-clear {
          position: absolute;
          width: 20px;
          height: 20px;
          fill: $color-blue;
          stroke: $color-blue;
          left: 100%;
          cursor: pointer;
          margin: -4px 0 0 2px;

          > svg {
            stroke-width: 1.6;
          }

          &:hover {
            fill: $color-danger;
            stroke: $color-danger
          }
        }
      }

      > .-hour {
        flex-basis: 4.17%;
        position: relative;
        pointer-events: none;
        border-bottom: 1px solid $color-separator;

        &:before {
          position: absolute;
          bottom: 16px;
          right: -10px;
          width: 20px;
          text-align: center;
          content: attr(data-hour)
        }

        &:after {
          position: absolute;
          content: '';
          right: 0;
          bottom: 0;
          height: 10px;
          border-left: 1px solid $color-separator;
        }

        > .-minutes {
          left: 16.67%;
          right: 16.67%;
          margin-left: -0.5px;

          &,
          &:before,
          &:after {
            position: absolute;
            border-left: 1px solid $color-separator;
            border-right: 1px solid $color-separator;
            height: 4px;
            bottom: 0;
          }

          &:before {
            content: '';
            margin-left: -1px;
            margin-right: -0.5px;
            left: 25%;
            right: 25%;
          }

          &:after {
            content: '';
            margin-left: -0.5px;
            left: 50%;
            border-right: none;
          }
        }
      }
    }
  }
}
