@import "../../consts";

.status {
  display: flex;
  align-items: center;
  color: inherit;
  
  > .icon {
    margin-left: -6px;
    margin-right: 2px;
    fill: $color-tertiary;
  }
  
  &.-success {
    color: darken($color-success, 5%);

    > .icon {
      fill: $color-success;
    }
  }

  &.-danger {
    color: $color-danger;

    > .icon {
      fill: $color-danger;
    }
  }

  &.-accent {
    color: $color-blue;
    > .icon {
      fill: $color-blue;
    }
  }
  
  &.-soft {
    color: inherit;
  }
}