@import "../../consts";

.navigation {
  flex-basis: $size-navigation;
  min-width: $size-navigation;
  background: $color-menu;
  position: relative;
  display: flex;
  flex-direction: column;

  &-logo {
    fill: $color-white;
    padding: 16px 14px;
    display: block;
    min-height: 68px;

    > svg {
      width: 36px;
      height: 36px;
      display: block;
    }
    
    > img {
      width: 46px;
      margin: 0 0 0 -5px;
    }
  }

  &-menu {
    flex-basis: 100%;
    padding: 10px 12px;
  }

  &-user {
    padding: 16px 14px;
  }
}

.user-auth-popup {
  z-index: 1000;
}