@import "../consts";

.map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  background: $color-page;

  &.-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &-preview {
    position: relative;
    width: 100%;
    height: 269px;
    overflow: hidden;
    background: $color-page;

    * + & {
      margin-top: 9px;
    }

    .section-header > &.-hidden {
      margin-top: -270px;
      opacity: 0;
      visibility: hidden;
    }

    &.-fullscreen {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin-top: 0 !important;
      z-index: 1000;
    }

    &.-hidden {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: -1000px;
      left: -1000px;
    }

    .section-header > &:last-child {
      margin-bottom: -5px;
    }
  }

  &-view {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &,
  &-preview {
    .mapboxgl {
      &-canvas {
        outline: none;
        position: absolute;
      }

      &-control-container {
        display: none;
      }
    }
  }

  &-marker {
    @include transition('box-shadow', short);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background: $color-blue;
    border-radius: 36px;
    color: $color-white;
    fill: $color-white;
    stroke: $color-white;
    border: 2px solid $color-white;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba($color-blue, 0.7);

    &.-selected {
      box-shadow: 0 0 0 10px rgba($color-blue, 0.7)
    }

    &.-cluster {
      @include text-control();
      font-weight: 500;
      color: $color-primary;
      background: $color-white;

      > svg {
        position: absolute;
      }
    }
  }

  > .app-loader,
  &-preview > .app-loader {
    z-index: 4;
    background: rgba($color-white, 0.8);
  }

  > .app-message,
  &-preview > .app-message {
    z-index: 4;
    background: rgba($color-white, 0.9);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: unset;
  }

  &-search {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    max-width: 36px;
    width: 80%;
    @include transition('max-width');

    > .textbox > .popup.-input {
      background: rgba($color-white, 0.9);
    }

    > .textbox > .icon {
      fill: $color-primary;
      stroke: $color-primary;
    }

    &.-active {
      max-width: 340px;
    }
  }

  &-zoom {
    position: absolute;
    z-index: 3;
    bottom: 20px;
    right: 20px;
    overflow: hidden;
    border-radius: 40px;
    background: rgba($color-white, 0.9);
    border: 1px solid $color-quarternary;

    > .button,
    > .button > .button-box {
      border-radius: 40px;
    }
  }

  &-fullscreen {
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 10px;
    overflow: hidden;
    border-radius: 40px;
    background: rgba($color-white, 0.9);
    border: 1px solid $color-quarternary;

    > .button,
    > .button > .button-box {
      border-radius: 40px;
    }
  }

  &-preview > &-zoom {
    right: 10px;
    bottom: 10px;
  }

  &-preview.-fullscreen > &-zoom {
    right: 20px;
    bottom: 20px;
  }

  &-preview.-fullscreen > &-fullscreen {
    top: 20px;
    right: 20px;
  }

  &-preview-loader {
    position: absolute;
    z-index: 4;
    background: rgba($color-white, 0.8);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &-popup {
    @include transition('opacity, visibility');
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;

    &.-visible {
      opacity: 1;
      visibility: visible;
    }

    > .mapboxgl-popup-tip {
      width: 5px;
      height: 5px;
    }

    > .mapboxgl-popup-content {
      background: #FFF;
      padding: 9px 12px;
      border-radius: $br;
      box-shadow: 0 0 0 1px $color-quarternary;
    }

    &.mapboxgl-popup-anchor-top {
      flex-direction: column;
    }

    &.mapboxgl-popup-anchor-left {
      flex-direction: row;
    }

    &.mapboxgl-popup-anchor-bottom  {
      flex-direction: column-reverse;
    }

    &.mapboxgl-popup-anchor-right {
      flex-direction: row-reverse;
    }
  }

  &-popup-board {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 250px;

    > * {
      margin: 2px 0;
    }

    > .-title {
      @include text-body(medium);
      color: $color-primary;
      margin-right: 10px;
    }

    > .-status {
      @include text-body();
      color: $color-secondary;
      margin-right: 10px;
    }

    > .-address {
      @include text-body(medium);
      color: $color-secondary;
      flex-basis: 100%;
    }
  }
}

@supports (backdrop-filter: blur(4px)) {
  .map > .app-message,
  .map-preview > .app-message {
    background: rgba($color-white, 0.7);
    backdrop-filter: blur(4px);
  }

  .map > .app-loader,
  .map-preview > .app-loader {
    background: rgba($color-white, 0.6);
    backdrop-filter: blur(4px);
  }

  .map-zoom,
  .map-search > .textbox > .popup.-input {
    background: rgba($color-white, 0.8);
    backdrop-filter: blur(4px);
  }

  .map-popup > .mapboxgl-popup-content {
    background: rgba($color-white, 0.8);
    backdrop-filter: blur(4px);
  }
}
