// Sizes
$size-navigation: 64px;
$size-filter: 280px;

// Colors
$color-deep-blue: #001F7D;
$color-menu: #F5F7F7;
$color-separator: rgba(#000000, 0.1);
$color-black: #000000;
$color-blue: #5784FF;
$color-blue-secondary: #027BFF;
$color-danger: #FD3D00;
$color-success: #6CC700;
$color-primary: #3B4357;
$color-secondary: #828DA2;
$color-tertiary: #CBCEE2;
$color-quarternary: #EBEDF0;
$color-page: #F7F8FE;
$color-white: #FFFFFF;
$color-button-active: #E9ECFF;
$color-border-active: rgba(3, 40, 76, 0.1);
$color-background-hover: rgba(3, 40, 76, 0.03);
$color-background-active: rgba(3, 40, 76, 0.07);
$color-background-active-hover: rgba(3, 40, 76, 0.09);
$color-button-hover: rgba($color-button-active, 0.3);

// Misc
$br: 4px;

// Fonts
$regular: 400;
$medium: 500;

// Mixins
@mixin text-body($weight: inherit) {
  font-size: 12px;
  line-height: 14px;
  @if ($weight == inherit) {
    font-weight: inherit;
  } @else if ($weight == regular) {
    font-weight: $regular;
  } @else if ($weight == medium) {
    font-weight: $medium;
  }
}

@mixin text-control () {
  font-size: 14px;
  line-height: 18px;
}

@mixin headline($size: 1) {
  @if ($size == 1) {
    font-size: 17px;
    line-height: 21px;
  } @else if ($size == 2) {
    font-size: 15px;
    line-height: 19px;
  } @else {
    font-size: 14px;
    line-height: 17px;
  }
  @if ($size == 4) {
    font-weight: $regular;
  } @else {
    font-weight: $medium;
  }
  margin-top: 0;
  margin-bottom: 0;
}


@mixin transition($properties, $speed: normal) {
  transition-property: unquote($properties);
  transition-timing-function: ease-out;

  @if $speed == short {
    transition-duration: 0.07s;
  } @else if $speed == long {
    transition-duration: 0.2s;
  } @else {
    transition-duration: 0.1s;
  }
}

@mixin clear() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 0;
  margin: 0;
}

@mixin pointer-area($size: 4px, $br: $br) {
  content: '';
  position: absolute;
  left: -$size;
  top: -$size;
  bottom: -$size;
  right: -$size;
  border-radius: $br
}