@import "../../consts";

.license-plate {
  @include text-body();
  line-height: inherit;
  display: flex;
  border: 1px solid $color-separator;
  border-radius: 2px;
  background: $color-white;
  min-width: 90px;
  letter-spacing: 0.02em;
  position: relative;
  margin: -1px 0;
  color: $color-primary;
  @include transition(background, short);

  &:hover {
    text-decoration: none;
  }

  &.-invalid {
    background: change-color($color-danger, $lightness: 95%);
  }

  > .-number {
    height: 20px;
    flex-basis: 100%;
    text-align: center;
    line-height: 20px;

    > strong {
      font-weight: 400;
    }

    > abbr {
      text-transform: uppercase;
      font-size: 0.9em;
      letter-spacing: 0.1em;
      padding: 0 0.1em;
      font-weight: $medium;
    }
  }

  > sup {
    height: 20px;
    line-height: 18px;
    font-size: 0.9em;
    min-width: 30%;
    font-weight: $medium;
    border-left: 1px solid $color-separator;
    text-transform: none;
    text-align: center;
  }
}

a.license-plate {
  &,
  > sup {
    border-color: change-color($color-blue, $lightness: 88%, $saturation: 90%);
  }
  
  & {
    background: change-color($color-blue, $lightness: 99%, $saturation: 70%);
  }
}