@import "../../ui-kit/consts";

.geozones {
  &-group {
    &-search {
      position: relative;
      width: 36px;

      > .textbox {
        position: absolute;
        right: 0;
        width: 36px;
        z-index: 10;
        @include transition('width', short);

        .icon {
          fill: $color-primary;
          stroke: $color-primary;
        }

        input:disabled ~ .icon {
          fill: $color-tertiary;
          stroke: $color-tertiary;
        }
      }

      &.-expanded {
        > .textbox {
          width: 240px;
        }

        > .textbox > .textbox-appearance {
          background: $color-white;
          @include transition('background-color', short);
        }
      }
    }
  }
  &-form {
    &-map {
      position: fixed;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }

    &-screenshot {
      position: relative;
      border-radius: $br;
      overflow: hidden;
      width: 100%;

      > .button {
        right: 10px;
        bottom: 10px;
        position: absolute;
        z-index: 1;
      }
    }
  }
}
