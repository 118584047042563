@import "src/ui-kit/consts";

.message {
  display: block;

  &-block {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    flex-direction: column;
    padding: 5px 20px;
    color: $color-primary;
    border: 1px solid transparent;
    margin: 0 16px;

    &:hover,
    &:visited,
    &:active {
      color: $color-primary;
    }

    .table-row:hover > & {
      background: rgba(3, 40, 76, 0.03);
      border-radius: 6px;
    }

    .table-row.-selected > & {
      background: $color-background-active;

      border: 1px solid $color-border-active;
      border-radius: 6px;

      z-index: 2;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    min-height: 36px;
    max-height: 36px;

    .svg-sidebar-mck {
      fill: none;

      ellipse {
        fill: white;
      }
      circle {
        stroke: $color-secondary;
      }
      path {
        fill: $color-secondary;
      }
    }

    .svg-sidebar-mobile {
      fill: none;

      circle {
        fill: $color-secondary;
      }
      path {
        fill: $color-secondary;
        stroke: $color-secondary;
      }
      rect {
        stroke: $color-secondary;
      }
    }

    .svg-trailer {
      fill: none;

      mask {
        fill: white;
      }
      path {
        stroke: $color-secondary;
      }
      circle {
        stroke: $color-secondary;
        fill: #f5f7f7;
      }
      rect {
        stroke: $color-secondary;
      }
    }

    .svg-danger {
      fill: none;
  
      path {
        fill: #ff3a2f;
      }
    }
  
    .svg-success {
      fill: none;
  
      path {
        fill: #32c758;
      }
    }
  }



  &-title {
    @include headline(2);
    color: $color-black;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 0;
    white-space: nowrap;
    margin-right: 20px;
    display: flex;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > em {
      white-space: nowrap;
      font-style: normal;
      margin-left: 0.2em;
    }
  }

  &-board-count {
    color: $color-secondary;
    white-space: nowrap;
  }

  &-button {
    z-index: 10;
  }

  &-dates {
    display: flex;
    align-items: center;
    color: $color-primary;
    fill: $color-secondary;
    stroke: $color-secondary;
    flex-shrink: 1;

    > .icon {
      min-width: 24px;
      margin-right: 6px;
    }
  }

  &-dates-popup {
    white-space: nowrap;
    min-width: 178px;

    .list-items {
      margin: 0;
      padding: 4px 0;
    }
  }

  &-icons {
    display: flex;
    flex-wrap: nowrap;
    fill: $color-secondary;
    // stroke: $color-secondary;

    > .icon:not(:last-child) {
      margin-right: 10px;
    }
  }

  &-icons,
  &-board-count,
  &-dates {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &-button {
    margin-right: 14px;
    margin-left: -6px;

    &:last-child {
      margin-right: -6px;
    }
  }

  &-images {
    &-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 1px;
      width: 100%;
    }

    white-space: nowrap;
    position: relative;
    padding: 0 21px;
    top: 47px;

    > .image {
      display: inline-block;
      vertical-align: top;
      margin-left: 4px;

      &:first-child {
        margin-left: 0;
      }
    }

    &-arrow {
      position: absolute;
      z-index: 4;
      top: 50%;
      opacity: 0;
      visibility: hidden;
      @include transition("opacity, visibility");

      &.-left {
        left: 10px;
      }

      &.-right {
        right: 10px;
      }
    }
  }

  &-block:hover > &-images-arrow.-visible {
    opacity: 1;
    visibility: visible;
  }

  &-log {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 3px 0;

    &,
    &:visited {
      color: inherit;
    }

    &:hover {
      color: $color-black;
    }

    > .-time {
      @include text-body();
      color: $color-secondary;
    }

    > .-name {
      @include text-control();
      flex-basis: 100%;
      font-weight: $medium;
    }

    > .-action {
      @include text-body();
      text-align: right;
      margin-top: -5px;
    }
  }
}

a.message-block:hover:not(.active) {
  background: $color-button-hover;
}
