@import "../../ui-kit/consts";

.message-preview {
  &-wrapper {
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    > .image {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: $br;

      &.-hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    > .button {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -25px;
      z-index: 10;
      width: 50px;
      min-height: 50px;
      transition-property: transform, opacity, visibility;
      transition-duration: 0.07s, 0.3s, 0.3s;
      cursor: pointer;

      &.-hidden {
        transition-duration: 0.07s, 1s, 1s;
        opacity: 0;
        visibility: hidden;
      }
    }

    > .button > .button-box {
      background: rgba($color-blue, 0.8);
      height: 50px;
      border-radius: 25px;
      padding: 5px;
      fill: $color-white;
      stroke: $color-white;
      box-shadow: 0 0 0 1px rgba($color-black, 0.1);

      > .icon {
        width: 40px;
        height: 40px;
      }
    }

    > .button:hover > .button-box {
      background: rgba($color-blue, 0.9);
    }

    > .button:active > .button-box {
      background: rgba($color-blue, 1);
      transform: scale3d(0.98, 0.98, 0.98);
    }
  }

  &-canvas {
    z-index: 1;
    position: relative;
    @include transition('opacity, visibility', long);
    transition-delay: 0.1s;
    border-radius: $br;

    &.-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &-progress {
    position: relative;
    border-radius: $br;
    min-height: 4px;
    margin-top: 15px;
    background: $color-quarternary;
    cursor: pointer;
    @include transition(background);

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: -5px;
      bottom: -5px;
      right: 0;
    }

    &.-loading {
      background: $color-page;
    }

    > span {
      min-width: 4px;
      width: 4px;
      height: 4px;
      border-radius: $br;
      background: $color-blue;
      display: block;
      pointer-events: none;
      position: relative;
      @include transition(background);
    }

    &.-loading > span {
      background: $color-tertiary;
    }
  }
}

@supports (backdrop-filter: blur(8px)) {
  .message-preview {
    &-wrapper > .button > .button-box {
      backdrop-filter: blur(8px);
    }
  }
}
