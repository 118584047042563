@import "../../consts";

.checkbox {
  @include text-body();
  min-height: 36px;
  min-width: 36px;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  margin-right: 15px;

  &.-disabled {
    pointer-events: none;
  }

  > input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &-appearance {
    @include transition('box-shadow, background-color', short);
    display: flex;
    align-items: center;
    min-width: 32px;
    justify-content: center;

    > .-box {
      width: 16px;
      height: 16px;
      box-shadow: inset 0 0 0 1px $color-separator;
      border-radius: $br;
      position: relative;

      > .icon {
        @include transition('opacity, transform', short);
        opacity: 0;
        stroke: $color-black;
        fill: $color-black;
        top: -2px;
        left: -1px;
        width: 20px;
        height: 20px;
        position: absolute;
      }
    }

    > .-label {
      flex-grow: 1;
      margin-left: 6px;
    }

    input:checked ~ & > .-box {
      background-color: $color-button-active;
      box-shadow: inset 0 0 0 1px $color-tertiary;

      > .icon {
        opacity: 1;
      }
    }

    *:active > & > .-box {
      background-color: $color-button-hover;

      > .icon {
        transform: translateY(1px);
      }
    }
  }

  &.-radio > &-appearance {
    > .-box {
      border-radius: 18px;

      > .icon {
        display: none;
      }

      &:before {
        position: absolute;
        content: '';
        left: 5px;
        top: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 6px;
        background: $color-black;
        opacity: 0;
        @include transition(opacity, short);
      }
    }
  }

  &.-radio > input:checked ~ * > .-box:before {
    opacity: 1;
  }

  &.-disabled > &-appearance  {
    > .-box {
      background: $color-quarternary;
      box-shadow: none;

      > .icon {
        fill: $color-tertiary;
        stroke: $color-tertiary;
      }
    }

    > .-label {
      color: $color-secondary;
    }
  }
}
