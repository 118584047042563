@import "../consts";

.tiles {
  margin: -144px -20px 0;

  > .ReactVirtualized__Grid {
    outline: none;
  }

  &-row {
    display: flex;
  }

  &-item {
    @include transition(background);
    flex-basis: 100%;
    display: flex;
    align-items: stretch;
    margin-bottom: -1px;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 20px;
      right: 20px;
      top: 0;
      border-top: 1px solid $color-separator
    }

    &:hover {
      background: rgba(3, 40, 76, 0.03);
      border-radius: 6px;
      z-index: 1;

      &:before {
        display: none;
      }
    }

    &.-active {
      background: $color-border-active;
      border: 1px solid $color-border-active;
      border-radius: 6px;
      z-index: 2;

      &:before {
        display: none;
      }
    }

    &.-disabled {
      pointer-events: none;
    }
  }

  &-block {
    @include transition(background);
    flex-basis: 100%;
    max-width: 100%;
    display: block;
    padding: 14px 20px;
    color: $color-primary;
    position: relative;

    > .image,
    > .image-slider {
      margin-top: 14px;
    }

    > .image,
    > .image-slider .image {
      padding-top: 75%;
    }

    > .datum + .datum {
      margin-top: -6px;
    }
  }

  &-image-header {
    position: absolute;
    z-index: 4;
    height: 32px;
    top: 47px;
    left: 20px;
    right: 20px;
    overflow: hidden;

    > * {
      @include transition('transform, background');
      fill: $color-white;
      stroke: $color-white;
      color: $color-white;
      background: rgba($color-blue, 0.8);
      backdrop-filter: blur(8px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 32px;
      transform: translate(0, -100%);

      &:hover {
        background: $color-blue;
        fill: $color-white;
        stroke: $color-white;
        color: $color-white;
      }
    }
  }

  &-item:hover &-image-header > * {
    transform: none;
  }

  &-text {
    @include text-body();
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 28px;
    display: block;
  }

  &-title {
    @include headline(2);
    color: $color-black;
    display: flex;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > em {
      white-space: nowrap;
      font-style: normal;
      margin-left: 0.2em;
    }
  }

  &-row:first-child > &-item:before {
    display: none;
  }
}
