@import "../../consts";

.timepicker {
  @include text-control();
  display: inline-flex;
  margin-top: 1px;
  position: relative;
  color: $color-secondary;

  & + & {
    margin-left: 1.6em;

    &:before {
      content: '—';
      position: absolute;
      left: -1.25em;
      top: 0.45em;
    }

    &.-small:before {
      top: 0.5em
    }
  }

  > input {
    @include clear();
    color: $color-primary;
    fill: $color-primary;
    stroke: $color-primary;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    border-radius: $br;
    box-shadow: inset 0 0 0 1px $color-quarternary;
    height: 32px;
    padding: 0 7px;
    position: relative;
    z-index: 1;
    text-align: center;

    &.-placeholder {
      color: $color-secondary;
    }

    &.-hidden {
      opacity: 0.5;
      box-shadow: none;
    }

    &:disabled {
      color: $color-secondary;
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin-left: -3px;
    }

    &::-webkit-clear-button,
    &::-moz-clear-button {
      display: none;
    }

    &::-webkit-inner-spin-button,
    &::-moz-inner-spin-button {
      display: none;
    }

    &.-error {
      color: change-color($color-danger, $lightness: 35%);
      box-shadow: inset 0 0 0 1px change-color($color-danger, $lightness: 80%, $saturation: 70%);
    }
  }

  &.-small {
    @include text-body();

    > input {
      height: 28px;
    }
  }

  &.-hidden {
    opacity: 0.5;

    > input {
      box-shadow: none;
    }
  }
}
