.tags {
  min-height: 300px;

  &-controls {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 4px;
    right: 4px;

    *:hover > & {
      opacity: 1;
      visibility: visible;
    }
  }

  &-edit {
    display: flex;
    align-items: center;

    > .button {
      margin-left: 10px;
    }
  }

  &-colors {
    > .button {
      margin: 1px 0 0 1px;
    }

    max-width: 112px;
    padding: 0 1px 1px 0;
  }
}
