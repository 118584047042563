@import "ui-kit/consts";

body {
  margin: 0;
  background: $color-page;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include text-body(regular);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  color: $color-blue;
  text-decoration: none;

  &:hover {
    color: darken($color-blue, 10%);
  }
}

.-highlight {
  font-weight: $medium;
  background-color: #FFEEAA;
  border-radius: $br;
}
