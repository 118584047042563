@import "../../consts";

.badge {
  @include text-body(medium);
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin: 2px;
  display: inline-flex;
  border-radius: 20px;
  min-width: 20px;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  background: $color-blue;
  color: $color-white;
  
  &.-disabled {
    background: $color-tertiary;
  }
  
  * + & {
    margin-left: 4px;
  }
  
  & + * {
    margin-left: 2px;
  }
  
  .tiles-title > & {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .tiles-title > * + & {
    margin-left: 6px;
  }
}