@import "../../consts";

.user {
  display: flex;
  padding-left: 40px;
  flex-direction: column;
  justify-content: center;
  
  min-height: 32px;
  position: relative;
  
  > .delete {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -8px;
  }
  
  > .delete ~ .-email,
  > .delete ~ .-name, {
    padding-right: 24px;
  }
  
  &-avatar {
    @include text-control();
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    left: -1px;
    background: $color-quarternary;
    overflow: hidden;
    
    > .-initials {
      @include text-body(medium);
      line-height: 32px;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-secondary;
    }
  }
  
  > .-name {
    @include text-body(medium);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  > .-email {
    color: $color-secondary;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  & + & {
    margin-top: 15px;
  }
  
  &-list {
    margin: 16px 0 0 0;
  }
}