@import "../consts";

.form {
  &-section {
    * + & {
      margin-top: 25px;
    }

    > hr {
      opacity: 0;
      height: 10px;
      border: none;
      margin: 0;
    }
  }

  &-control {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-control.-inline {
    flex-direction: row;
    align-items: flex-start;
  }

  &-label {
    color: $color-primary;
    margin-bottom: 6px;
  }

  &-hint {
    color: $color-secondary;
    @include text-body();
    margin-top: 10px;
    display: block;
  }

  &-control:not(.-inline) > &-label {
    margin-top: 5px;
  }

  &-control:first-child:not(.-inline) &-label {
    margin-top: 0;
  }

  &-error {
    margin-top: 5px;
    padding-left: 15px;
    color: change-color($color-danger, $saturation: 65%, $lightness: 55%);
    position: relative;
    display: block;

    &:before {
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 3px;
      left: 3px;
      top: 4px;
      background: $color-danger;
    }
  }

  &-input {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    align-items: flex-start;

    > * + .button {
      margin-left: 5px;
    }
  }

  &-control > &-input > &-control {
    margin-bottom: 0;
    flex-basis: 100%;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  &-control.-inline > &-label {
    display: flex;
    align-items: center;
    height: 36px;
    flex-basis: 20%;
    min-width: 130px;
    margin: 0;
  }
}
