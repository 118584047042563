@import "../consts";

.app {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  @include transition('transform', short);

  .prompt.-visible ~ & {
    //transform: scale3d(0.97, 0.97, 0.97);
    pointer-events: none;
    user-select: none;
  }

  &-loader {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    > h1 {
      color: $color-secondary;
      @include headline();
      text-align: center;
      margin-top: 0;
    }
  }

  &-message {
    @include headline(4);
    max-width: 400px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    color: $color-secondary;

    > p {
      margin: 6px 0 0 0;

      &:first-child {
        margin-top: 0;
      }
    }

    > .button,
    > p > .button {
      margin-top: 10px;
    }

    > p > small,
    > small {
      @include text-body();
    }
  }
}
