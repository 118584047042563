@import "../../consts";

.prompt {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  z-index: 1000;
  background: rgba($color-white, 0);
  @include transition('background, backdrop-filter');

  &-window {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    background: $color-white;
    border-radius: $br;
    opacity: 0;
    visibility: hidden;
    transform: scale3d(0.95, 0.95, 0.95);
    box-shadow: 0 20px 30px -5px rgba($color-black, 0.1), 0 0 0 0.5px rgba($color-black, 0.05);
    @include transition('transform, opacity, visibility', short);
  }

  &-message {
    line-height: 16px;
    padding: 30px 40px 10px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-break: break-word;
    max-width: 900px;

    &.-loader {
      padding-bottom: 20px;
      padding-top: 0;
      color: $color-secondary;
    }
  }

  &-loader {
    text-align: center;
    padding: 40px 40px 0;
    color: $color-secondary;
    margin-bottom: -6px;
  }

  &-component {
    padding: 30px 30px 10px;
  }

  &-footer {
    display: flex;
    justify-content: center;
    padding: 15px;

    > * + .button {
      margin-left: 10px;
    }
  }

  &.-visible {
    background: rgba($color-black, 0.2);
  }

  &.-visible > &-window {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
}
