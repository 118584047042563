@import "../../ui-kit/consts";

.message-form {

  .svg-sidebar-mck {
    fill: none;
    
    ellipse {
      fill: white;
    }
    circle {
      stroke: $color-secondary;
    }
    path {
      fill: $color-secondary;
    }
  }

  &-item {
    & + & {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid $color-separator;
    }

    > .-header {
      display: flex;
      align-items: center;

      > .-title {
        @include headline(2);
        margin: 0 20px;
        display: flex;
        width: 9em;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: -1px;

        > .badge {
          position: relative;
          top: 1px;
        }
      }
    }
  }

  &-image {
    padding-left: 56px;
    margin-top: 10px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;

    .textbox,
    .button-box {
      background: $color-white;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-url {
    background: $color-primary;

    > .image-placeholder {
      > .icon {
        margin: 0 auto 4px;
        width: 32px;
        height: 32px;

        > svg {
          stroke-width: 0.8;
        }
      }

      fill: $color-white;
      stroke: $color-white;
      color: $color-white;
    }
  }

  &-images {
    white-space: nowrap;
    position: relative;
  }

  &-aside {
    width: 36px;
    position: absolute;
    left: 0;
    bottom: 42px;
    display: flex;
    flex-direction: column;

    > .textbox {
      margin-bottom: 10px;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    margin-top: 10px;

    > .textbox {
      width: 46px;
    }

    > .buttons {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  &-settings {
    display: flex;
    align-items: stretch;
    margin: 10px 0 20px 0;
  }

  &-list {
    position: relative;

    &.-boards {
      min-width: 380px;
      max-width: 380px;
    }

    &.-dates {
      min-width: 280px;
    }

    &.-tags {
      max-width: 600px;

      > .list-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      > .list-items > .list-item {
        width: auto;
      }
    }

    & + & {
      margin-left: 20px;
      padding-left: 20px;
    }

    > .button {
      margin-top: 4px;
      margin-left: -12px;
    }
  }

  &-list + &-list {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 1px;
      top: 0;
      bottom: 4px;
      background: $color-separator
    }
  }

  &-date {
    width: 300px;

    &-block {
      padding: 10px;
    }

    &-header {
      display: flex;
      align-items: center;
      color: $color-primary;
      position: relative;

      > .icon {
        fill: $color-blue;
        stroke: $color-blue;
        margin-right: 10px;
      }

      > .-title {
        flex-grow: 1;
      }

      > button {
        @include clear();
        fill: $color-secondary;
        stroke: $color-secondary;
        cursor: pointer;
        position: absolute;
        right: -2px;
        top: 0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        > .icon {
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
          @include transition('transform', short);

          > svg {
            stroke-width: 2;
          }
        }

        &.-expanded > .icon {
          transform: rotate(-90deg);
        }
      }

      > button.-delete {
        fill: $color-danger;
        stroke: $color-danger;

        > .icon {
          transform: rotate(-90deg);
          width: 24px;
          height: 24px;

          > svg {
            stroke-width: 1.2;
          }
        }
      }
    }

    &-content {
      padding: 0 30px 0 34px;
      max-height: 0;
      overflow: hidden;

      > .-error {
        color: $color-danger;
        margin-top: 5px;
      }

      > .calendar {
        margin: 0 -12px;
      }

      &.-expanded {
        max-height: 300px;
        padding-top: 10px;
      }
    }

    &-weekdays {
      padding-top: 0;
    }

    &-time {
      color: $color-secondary;
    }
  }

  body > &-image .-sort-handler > .button-box {
    background-color: $color-button-active;
  }
}


@supports (backdrop-filter: blur(4px)) {
  .message-form-image {
    .textbox,
    .button-box {
      background: rgba($color-white, 0.8);
      backdrop-filter: blur(4px);
    }
  }
}

.popup-wrapper {
  display: flex;
  gap: 20px;

  .picture-wrapper {
    background-color: black;
    width: 800px;
    height: 600px;

  }
  
  .options-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
  }
}