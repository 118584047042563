@import "../../consts";

.layout {
  display: flex;
  flex-basis: 100%;
  align-items: stretch;
  transform-origin: 50% 30%;
  @include transition(transform, short);

  &-area {
    @include transition('max-width, min-width');
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    background: $color-white;
    overflow: hidden;
    box-sizing: content-box;

    & + & {
      border-left: 1px solid $color-separator;
    }

    &.-collapsed {
      max-width: 0;
    }

    &.-pale {
      // background: $color-page;
    }
  }

  &-wrapper {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    position: relative;
  }

  &-space {
    flex-grow: 1;
  }

  //.modal.-visible ~ & {
  //  transform: scale3d(0.98, 0.98, 0.98);
  //}
}
