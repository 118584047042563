@import "../../consts";

.popup {
  position: absolute;
  top: 0;
  max-height: 1px;
  border-radius: $br;
  padding: 1px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  background: $color-white;
  @include transition('opacity, visibility, z-index, box-shadow, background');

  > * {
    border-radius: $br;
  }

  &.-input {
    min-height: 36px;
    max-height: 36px;
    opacity: 1;
    visibility: visible;
    z-index: auto;
    left: 0;
    right: 0;
    overflow: hidden;
    box-shadow: inset 0 0 0 1px $color-quarternary;
    background: transparent;
    width: 100%;
    max-width: 100%;

    input:focus ~ & {
      box-shadow: inset 0 0 0 1px $color-tertiary;
      background: rgba($color-white, 0.7);
    }

    &.-small {
      min-height: 28px;
      max-height: 28px;
    }

    &.-opened {
      z-index: 99;
      max-height: 346px;
      background: $color-white !important;
    }

    &.-opened.-small {
      max-height: 338px;
    }
  }

  &.-opened {
    opacity: 1;
    visibility: visible;
    z-index: 99;
    box-shadow: inset 0 0 0 1px $color-tertiary;
    max-height: unset;
  }

  &-control:focus,
  &-control.-opened {
    position: relative;
    z-index: 100;

    & ~ .icon {
      z-index: 100;
    }

    & ~ .textbox-button {
      z-index: 100;
    }
  }

  &-menu {
    @include text-body();
    color: $color-primary;
    fill: $color-secondary;
    stroke: $color-secondary;
    padding: 3px;
    margin: 0;

    > li {
      padding: 11px 13px 11px 36px;
      margin: 0;
      list-style: none;
      border-radius: $br;
      height: 36px;
      position: relative;
      cursor: default;
      white-space: nowrap;

      > .icon,
      > a > .icon {
        position: absolute;
        top: 6px;
        left: 7px;
      }

      &:hover {
        background: change-color($color-button-active, $lightness: 98%);
      }

      &:active {
        background: $color-button-active;
      }

      &.-danger {
        color: change-color($color-danger, $lightness: 45%);
        fill: $color-danger;
        stroke: $color-danger;

        &:hover {
          background: change-color($color-danger, $lightness: 98.5%);
        }

        &:active {
          background: change-color($color-danger, $lightness: 97%);
        }
      }

      &[aria-disabled=true] {
        pointer-events: none;
        color: rgba($color-secondary, 0.8);
        fill: $color-tertiary;
        stroke: $color-tertiary;
      }

      > a {
        display: block;
        margin: -11px -13px -11px -36px;
        padding: 11px 13px 11px 36px;
        color: inherit;
      }
    }
  }

  &-control:focus ~ & {
    box-shadow: inset 0 0 0 1px $color-tertiary;
  }

  &-control[aria-invalid] ~ &.-input {
    box-shadow: inset 0 0 0 1px change-color($color-danger, $lightness: 90%, $saturation: 70%);
  }

  &-control[aria-invalid] ~ &.-input.-opened {
    box-shadow: inset 0 0 0 1px change-color($color-danger, $lightness: 80%, $saturation: 70%);
  }
}
