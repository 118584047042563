@import "../../consts";
@import "../../consts";

.list {
  &-label {
    @include text-body(medium);
    color: $color-black;
    display: block;
    height: 36px;
    padding: 15px 0 7px;
  }

  &-label:first-child {
    padding-top: 0;
    height: 21px;
  }

  &-items {
    margin: 0 -12px;

    &.-inline {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-item {
    @include clear();
    @include text-body();
    @include transition("background", short);
    height: 36px;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    color: $color-primary;
    padding: 0 12px;
    width: 100%;
    border-radius: $br;
    text-align: left;
    position: relative;
    cursor: default;
    border: 1px solid transparent;
    fill: $color-blue-secondary;

    .svg-sidebar-mck {
      fill: none;

      ellipse {
        fill: white;
      }
      circle {
        stroke: $color-blue-secondary;
      }
      path {
        fill: $color-blue-secondary;
      }
    }

    .svg-sidebar-mobile {
      fill: none;

      circle {
        fill: $color-blue-secondary;
      }
      path {
        fill: $color-blue-secondary;
        stroke: $color-blue-secondary;
      }
      rect {
        stroke: $color-blue-secondary;
      }
    }

    .svg-trailer {
      fill: none;

      mask {
        fill: white;
      }
      path {
        stroke: $color-blue-secondary;
      }
      circle {
        stroke: $color-blue-secondary;
        fill: #f5f7f7;
      }
      rect {
        stroke: $color-blue-secondary;
      }
    }

    &.-depth1 {
      padding-left: 32px;
    }

    &.-depth2 {
      padding-left: 64px;
    }

    > span.-label {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;

      > .-description {
        display: block;
        font-size: 0.9em;
        margin-bottom: -1px;
        color: $color-secondary;
      }

      &.-empty {
        color: $color-secondary;
      }
    }

    > span.-badge {
      white-space: nowrap;
      margin-left: 10px;
      color: $color-secondary;
    }

    &.-active:hover {
      background: $color-background-active;
      border: 1px solid $color-border-active;
    }

    > .icon {
      min-width: 24px;
      margin-right: 10px;
    }

    &.-selected {
      background: $color-background-active;
      border: 1px solid $color-border-active;
    }

    &.-selected:hover {
      background: $color-background-active-hover;
      border: 1px solid $color-border-active;
    }

    > .-button {
      margin-right: -5px;
      margin-left: 5px;
      cursor: pointer;
      @include transition("opacity, fill, stroke", short);

      &:hover {
        opacity: 0.8;
      }
    }

    > .-button + .-button {
      margin-left: 10px;
    }

    > .-edit,
    > .-delete {
      opacity: 0;
    }

    &:hover > .-edit {
      opacity: 1;

      &:hover {
        fill: $color-blue;
        stroke: $color-blue;
      }
    }

    &:hover > .-delete {
      opacity: 1;

      &:hover {
        fill: $color-danger;
        stroke: $color-danger;
      }
    }
  }

  &-items.-inline > &-item {
    flex-basis: 100%;
    width: auto;
    min-width: 200px;
    border-right: 1px solid #fff;
  }

  &-items.-inline.-cols5 > &-item {
    flex-basis: 20%;
  }

  &-items.-inline.-cols4 > &-item {
    flex-basis: 25%;
  }

  &-items.-inline.-cols3 > &-item {
    flex-basis: 33%;
  }

  &-items.-inline.-cols2 > &-item {
    flex-basis: 50%;
  }

  &-checkbox {
    min-width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    margin-left: 8px;

    &:before {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      left: 4px;
      top: 4px;
      box-shadow: inset 0 0 0 1px $color-tertiary;
      border-radius: 2px;
    }

    > .icon {
      width: 16px;
      height: 16px;
      fill: $color-white;
      position: absolute;
      left: 4px;
      top: 4px;
      opacity: 0;
    }

    &.-checked {
      &:before {
        background: $color-blue;
        box-shadow: none;
      }

      > .icon {
        opacity: 1;
      }
    }

    &.-partial:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background: $color-blue;
      border-radius: 2px;
      left: 9px;
      top: 9px;
    }
  }

  &-search {
    margin: 6px -7px 6px 0;
    font-weight: $regular;
    display: block;
  }
}
