@import "../../consts";

.calendar {
  padding: 12px 8px 8px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &-title {
    @include text-body(medium);
    margin: 0 0 15px;
    padding: 0 3px;
    min-width: 100%;
    color: $color-secondary;
  }

  .popup.-input > & {
    margin-top: 34px;
    border-top: 1px solid $color-separator;
  }

  *:focus {
    outline: none;
  }

  .DayPicker {
    @include text-body();
    width: 100%;

    &-wrapper {
      padding: 0;
    }

    &-Month {
      margin: 0;
    }

    &-NavButton {
      top: 0;
      margin: 0;

      &--prev {
        right: 20px;
      }

      &--next {
        right: 0;
      }
    }

    &-Caption  {
      padding: 0 4px;
      margin: 0;
      width: 100%;

      > div {
        @include text-control();
        font-weight: $medium;
      }
    }

    &-Month {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-Weekdays {
      margin: 0;
      color: $color-secondary;
      padding: 16px 0 0;
    }

    &-Weekday {
      max-width: 32px;
      padding: 0;
      width: 32px;
    }

    &-Day {
      @include text-control();
      max-width: 32px;
      width: 32px;
      height: 32px;
      padding: 0;
      border-radius: $br;
      color: $color-primary;

      &--today:not([aria-disabled=true]) {
        font-weight: $medium;
        color: $color-blue;
        box-shadow: inset 0 0 0 1px $color-button-active;
      }

      &:not([aria-disabled=true]):hover {
        background: $color-button-hover !important;
        color: $color-primary !important;
      }

      &--selected[aria-disabled=true] {
        background: $color-button-hover;
      }

      &--selected:not([aria-disabled=true]),
      &--selected:not([aria-disabled=true]):hover {
        background: $color-button-active !important;
        color: $color-primary !important;
      }

      &[aria-disabled=true] {
        pointer-events: none;
        color: $color-tertiary;
      }
    }
  }

  &-time {
    width: 100%;
    text-align: left;
    padding: 4px 6px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > .checkbox {
      margin-right: 10px;
    }
  }
}
