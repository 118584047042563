@import "../../consts";

.image {
  @include transition('background-color', long);
  transition-duration: 0.3s;
  background-color: $color-background-active;
  position: relative;
  padding-top: 70%;
  outline: none;

  > .loader {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: $color-secondary;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    @include headline(1);

    &.-semi {
      background: rgba($color-black, 0.3);
      color: $color-white;
    }
  }

  &-view {
    @include transition('opacity, visiblity', long);
    transition-duration: 0.3s;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: inherit;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none;

    &.-video {
      overflow: hidden;
      background: $color-black;

      > video {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    &.-clickable  {
      cursor: pointer;
    }

    &.-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &.-cover {
      background-size: cover;
    }
  }

  &-placeholder,
  &-error {
    @include text-body();
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $color-secondary;
    white-space: normal;
  }

  &-error {
    max-width: 200px;

    > .button {
      position: relative;
      top: 6px;
    }
  }

  &-placeholder {
    word-break: break-word;
  }

  &-update {
    @include transition('opacity, visibility');
    position: absolute;
    right: 6px;
    bottom: 6px;
    opacity: 0;
    visibility: hidden;
  }

  &:hover > &-update {
    opacity: 1;
    visibility: visible;
  }

  &-slider {
    overflow: hidden;
    display: block;
    position: relative;

    > .image {
      margin-top: 0;
      float: left;
      margin-right: -100%;
      width: 100%;
      transform: translate(-100%, 0);
      @include transition(transform, long);

      &.-selected {
        transform: none;
      }
    }

    > .image.-selected ~ .image {
      transform: translate(100%, 0);
    }

    &-button {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      @include transition(opacity, short);
      opacity: 0;

      &.-prev {
        left: 6px;
      }

      &.-next {
        right: 6px;
      }

      svg {
        stroke-width: 1.5;
      }
    }

    &:hover > &-button {
      opacity: 1;
    }
  }
}
