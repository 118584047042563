@import "../../consts";

.tabs {
  display: flex;
  border-radius: $br;
  box-shadow: inset 0 0 0 1px $color-quarternary;
  
  > * + * {
    margin-left: -1px;
  }


  &.-wide {
    flex-basis: 100%;
    width: 100%;
    
    > * {
      flex-grow: 1;
      flex-basis: 100%;
    }
  }
  
  &.-blur {
    background-color: rgba($color-white, 0.9);
  }
  
  > .button.-clear > .button-box {
    color: $color-primary;
  }

  > .button.-clear.active > .button-box,
  > .button.-clear.-active > .button-box{
    color: $color-black;
  }
}

@supports (backdrop-filter: blur(8px)) {
  .tabs {
    &.-blur {
      background: rgba($color-white, 0.7);
      backdrop-filter: blur(8px);
    }

    &.-blur:hover > &-box {
      background: rgba($color-white, 0.9);
    }
  }
}