@import "../../consts";

.scroller {
  display: block;
  
  &-track {
    z-index: 5;
  }

  &-track.-vertical {
    @include transition(padding, long);
    left: auto;
    right: 1px;
    top: 1px;
    bottom: 1px;
    padding-left: 2px;
  }
  
  &-track.-horizontal {
    @include transition(padding, long);
    right: 1px;
    left: 1px;
    bottom: 1px;
    top: auto;
    padding-top: 2px;
  }
  
  &-track.-hidden {
    display: none;
  }

  &-thumb {
    @include transition(background, short);
    border-radius: 1px;
    background: $color-tertiary;
  }

  &-track:hover,
  &-track:active {
    padding-left: 0;
    padding-top: 0;
  }

  &-track:active > &-thumb {
    background: $color-secondary;
  }
}
