@import "../../consts";

.plate-textbox {
  border-radius: $br;
  box-shadow: inset 0 0 0 1px $color-quarternary;
  position: relative;
  overflow: hidden;
  display: block;
  
  &.-disabled {
    background: rgba($color-quarternary, 0.5);
    pointer-events: none;
  }
  
  &-values {
    padding: 0 15px;
    width: 128px;
    display: flex;
    
    &:before {
      position: absolute;
      content: '';
      left: 86px;
      width: 1px;
      top: 0;
      height: 100%;
      z-index: 2;
      background: $color-separator;
    }
  }
  
  &:before {
    top: 0;
    height: 16px;
  }
  
  &-input {
    appearance: none;
    height: 34px;
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    background: transparent;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $color-black;
    
    &::placeholder {
      color: $color-secondary;
    }
    
    &:disabled {
      color: $color-secondary;
    }
    
    
    &:focus {
      outline: none;
    }

    &.-i0,
    &.-i2 {
      width: 12px;
    }
    
    &.-i1 {
      width: 29px;
      font-size: 1.15em;
    }
    
    &.-i2 {
      width: 22px;
    }
    
    &.-i3 {
      width: 30px;
      text-align: center;
      margin-left: 14px;
      position: relative;
      top: -1px
    }
  }
}