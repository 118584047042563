@import "../../consts";

$danger-text: change-color($color-danger, $lightness: 45%);
$success-text: change-color($color-success, $lightness: 33%);
$accent-text: change-color($color-blue, $lightness: 50%);

.button {
  @include clear();
  @include text-body();
  display: inline-flex;
  align-items: center;
  cursor: default;

  &-label {
    @include transition(color, short);
    margin-right: 12px;
    color: $color-secondary;
    flex-grow: 1;
    white-space: nowrap;
  }

  &:hover > &-label {
    color: $color-primary;
  }

  &.-strong {
    font-weight: 500;
  }

  &-box {
    @include transition("box-shadow, background-color, fill, stroke", short);
    height: 36px;
    flex-basis: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: $br;
    box-shadow: inset 0 0 0 1px $color-quarternary;
    padding: 6px 18px;
    fill: $color-primary;
    stroke: $color-primary;
    color: $color-black;

    .svg-trash {
      fill: none;
      stroke: none;
      fill: $color-blue-secondary;
    }

    > .icon {
      margin: 0 -12px;
    }
  }

  &.-wide {
    width: 100%;
  }

  &.-white > &-box {
    background-color: $color-white;
  }

  &.-danger > &-box {
    color: $danger-text;
    fill: $danger-text;
    stroke: $danger-text;
  }

  &.-accent > &-box {
    color: $accent-text;
    fill: $accent-text;
    stroke: $accent-text;
  }

  &.-success > &-box {
    color: $success-text;
    fill: $success-text;
    stroke: $success-text;
  }

  &.-clear:hover > &-box,
  &.-clear:focus > &-box {
    background: $color-background-hover;
  }

  &:not(.-active):hover > &-box,
  &:not(.-active):focus > &-box {
    background: $color-background-hover;
  }

  &.-danger:not(.-active):hover > &-box,
  &.-danger:not(.-active):focus > &-box {
    background: $color-background-hover;
  }

  &.-success:not(.-active):hover > &-box,
  &.-success:not(.-active):focus > &-box {
    background: $color-background-hover;
  }

  &.-accent:not(.-active):hover > &-box,
  &.-accent:not(.-active):focus > &-box {
    background: $color-background-hover;
  }

  &:not(.-active):active > &-box {
    background: $color-background-active;
    box-shadow: none;
  }

  &.-danger:not(.-active):active > &-box {
    background: $color-background-hover;
    box-shadow: none;
  }

  &.-success:not(.-active):active > &-box {
    background: $color-background-hover;
    box-shadow: none;
  }

  &.-active > &-box,
  &.active > &-box,
  &.-active:hover > &-box,
  &.active:hover > &-box,
  &.-active:focus > &-box,
  &.active:focus > &-box {
    color: $color-black;
    background-color: $color-background-active;
    border: 1px solid $color-border-active;
  }

  &.active:hover > &-box,
  &.-active:hover > &-box {
    box-shadow: inset 0 0 0 1px rgba($color-tertiary, 0.5);
  }

  &.-clear > &-box,
  &.-clear:hover > &-box,
  &.-clear:focus > &-box {
    box-shadow: none;
  }

  &.-white:hover > &-box,
  &.-white:focus > &-box {
    background-color: $color-white;
    box-shadow: inset 0 0 0 1px $color-tertiary;
  }

  &.-white:active > &-box {
    background-color: $color-background-active;
    box-shadow: inset 0 0 0 1px rgba($color-tertiary, 0.5);
  }

  &.-blur > &-box {
    background-color: rgba($color-white, 0.9);
    box-shadow: none;
  }

  &.-blur:hover > &-box {
    background: rgba($color-white, 1);
  }

  &.-fill > &-box {
    background-color: $color-blue;
    fill: $color-white;
    stroke: $color-white;
    color: $color-white;
    box-shadow: none;
  }

  &.-fill:focus > &-box,
  &.-fill:hover > &-box {
    background-color: darken($color-blue, 3%);
    fill: $color-white;
    stroke: $color-white;
    color: $color-white;
    box-shadow: none;
  }

  &.-fill.-danger > &-box {
    background-color: $color-background-hover;
    fill: $color-white;
    stroke: $color-white;
    color: $color-white;
    box-shadow: none;
  }

  &.-fill.-danger:hover > &-box,
  &.-fill.-danger:focus > &-box {
    background-color: $color-background-hover;
    fill: $color-white;
    stroke: $color-white;
    color: $color-white;
    box-shadow: none;
  }

  &.-fill.-success > &-box {
    background-color: $color-success;
    fill: $color-white;
    stroke: $color-white;
    color: $color-white;
    box-shadow: none;
  }

  &.-fill.-success:hover > &-box,
  &.-fill.-success:focus > &-box {
    background-color: darken($color-success, 3%);
    fill: $color-white;
    stroke: $color-white;
    color: $color-white;
    box-shadow: none;
  }

  &.-large {
    font-size: 14px;
  }

  &.-small > &-box {
    height: 28px;
    padding: 2px 12px;

    > .icon {
      width: 20px;
      height: 20px;
      margin: 0 -8px;
    }
  }

  &.-disabled {
    pointer-events: none;
  }

  &.-disabled > &-box {
    fill: $color-tertiary;
    stroke: $color-tertiary;
    color: $color-tertiary;
  }

  &.-disabled.-fill > &-box {
    background: $color-tertiary;
    fill: $color-white;
    stroke: $color-white;
    color: $color-white;
  }

  &.-disabled.-blur > &-box {
    color: darken($color-tertiary, 12%);
    fill: darken($color-tertiary, 12%);
    stroke: darken($color-tertiary, 12%);
  }

  &.-disabled > &-label {
    color: $color-tertiary;
  }

  &-caption {
    align-items: center;
    display: flex;

    > .badge {
      margin: 0 7px 0 -7px;
    }

    > * + .badge {
      margin: 0 -7px 0 7px;
    }

    .icon ~ & {
      margin-left: 15px;
    }

    > .icon {
      margin: 0 -12px;
    }
  }

  &.-round {
    border-radius: 50px;
  }

  &.-round > &-box {
    border-radius: 50px;
  }
}

@supports (backdrop-filter: blur(8px)) {
  .button {
    &.-blur > &-box {
      background: rgba($color-white, 0.8);
      backdrop-filter: blur(8px);
    }

    &.-blur:hover > &-box {
      background: rgba($color-white, 0.9);
    }
  }
}
