.icon {
  width: 24px;
  height: 24px;
  display: block;

  &.-inline {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;
  }

  > svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke-width: 1.2;

    > .-fill {
      display: none;
    }
  }

  &.-fill > svg > .-fill {
    display: block;

    & ~ * {
      display: none;
    }
  }
}
