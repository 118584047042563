@import "../consts";

.table {
  margin: 0 -20px;
  position: relative;

  &-head {
    position: sticky;
    top: 0;
    background: $color-white;
    border-bottom: 1px solid $color-separator;
    padding: 0 20px;
    margin: 0 -20px;
    overflow: hidden;
    z-index: 4;

    &-row {
      display: flex;
    }

    &-cell {
      padding: 3px 20px 8px 0;
      pointer-events: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      &:last-child {
        padding-right: 0;
      }
    }

    &-button {
      @include clear();
      max-width: 100%;
      position: relative;
      text-align: left;
      padding-right: 10px;
      color: $color-secondary;
      fill: $color-secondary;
      stroke: $color-secondary;
      @include transition("color, fill, stroke", short);

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        display: block;
      }

      > .icon {
        @include transition("transform, opacity", short);
        position: absolute;
        width: 12px;
        height: 12px;
        right: -6px;
        top: 1px;
        opacity: 0;
        transform: rotate(-90deg);

        > svg {
          stroke-width: 2.2;
        }
      }

      &.-clickable {
        cursor: pointer;
      }

      &.-active {
        color: $color-blue;
        fill: $color-blue;
        stroke: $color-blue;
      }

      &.-descending > .icon {
        transform: rotate(90deg);
      }

      &.-clickable:hover > .icon,
      &.-active > .icon {
        opacity: 1;
      }
    }
  }

  &-row {
    display: flex;
    cursor: default;
    padding: 0 20px;

    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 20px;
      right: 20px;
      height: 1px;
      background: $color-separator;
    }

    &:last-child:after {
      display: none;
    }

    &.-disabled {
      pointer-events: none;
    }
  }

  &-cell {
    @include transition(background, short);
    display: flex;
    align-items: center;
    padding-right: 20px;
    position: relative;
    color: $color-primary;

    &:hover {
      color: $color-primary;
    }

    &:first-child:before,
    &:last-child:after {
      content: "";
      background: inherit;
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      width: 20px;
    }

    &:last-child:after {
      right: auto;
      left: 100%;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &-placeholder {
    display: flex;
    align-items: center;
    padding-right: 20px;
    position: relative;
    color: $color-secondary;
  }

  &-row:hover > &-cell {
    background: $color-button-hover;
  }

  &-row.-selected > &-cell {
    background: $color-button-active;
    z-index: 2;
  }

  > .ReactVirtualized__Grid {
    outline: none;
  }

  &-icons {
    display: flex;
    flex-wrap: nowrap;
    fill: $color-secondary;
    // stroke: $color-secondary;

    .svg-trailer {
      fill: none;

      mask {
        fill: white;
      }
      path {
        stroke: $color-secondary;
      }
      circle {
        stroke: $color-secondary;
        fill: #f5f7f7;
      }
      rect {
        stroke: $color-secondary;
      }
    }

    > .icon {
      min-width: 24px;
    }

    > .icon + .icon {
      margin-left: 15px;
    }
  }

  &-accent {
    @include headline(3);
    color: $color-black;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 38px;
  }

  &-text {
    @include text-body();
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 28px;
    padding-left: 10px;
    margin-left: -10px;

    > .status:first-child {
      margin-left: -3px;
    }

    > .badge:first-child {
      margin-left: -2px;
    }
  }
}

@supports (backdrop-filter: blur(4px)) {
  .table-head {
    background: rgba($color-white, 0.9);
    backdrop-filter: blur(6px);
  }
}
