@import "../../consts";

.suggestions {
  @include text-control();
  padding: 0;
  color: $color-primary;
  fill: $color-secondary;
  stroke: $color-secondary;

  > [role=button] {
    margin: 0;
    padding: 8px 13px 8px;
    cursor: default;
    display: block;
    position: relative;

    &.-selected {
      background: $color-button-hover;
      font-weight: $medium;
    }

    &[aria-current] {
      background: change-color($color-button-active, $lightness: 98%);
    }

    &:active {
      background: $color-button-active;
    }

    &[aria-disabled] {
      pointer-events: none;
      color: $color-tertiary;
    }
  }

  &.-iconic > [role=button] {
    padding-left: 34px;
  }

  .popup.-input > &-wrapper > & {
    opacity: 0;
    visibility: hidden;
    border-radius: $br - 1px;
  }

  .popup.-input.-opened > &-wrapper > & {
    opacity: 1;
    visibility: visible;
  }

  .popup.-input > * > &-wrapper {
    margin: 35px 0 0 0;
  }

  .popup.-input.-small > * > &-wrapper {
    margin: 27px 0 0 0;
  }
}
