@import "../consts";

.section {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  position: relative;

  &-content {
    padding: 16px 20px;
  }

  &-header,
  &-footer {
    position: absolute;
    left: 0;
    width: 100%;

    &:empty {
      display: none;
    }
  }

  &-header {
    top: 0;
    z-index: 100;

    &-buttons {
      display: flex;
      flex-grow: 1;

      > .button {
        margin-right: 15px;
      }
    }
  }

  &-footer {
    bottom: 0;
    z-index: 5;
  }

  &-header-block,
  &-footer {
    display: flex;
    align-items: center;
  }

  &-header-block {
    position: relative;
    z-index: 1;
  }

  &-header-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 10px;
    right: 0;
    @include transition('bottom');

    &.-scrolled {
      bottom: 0;
    }
  }

  &-header-bg,
  &-footer {
    background: $color-white;
  }

  &-header + &-body &-content  {
    > .datum:first-child {
      margin-top: -6px;
    }
  }

  &-header-block {
    padding: 7px 0 16px 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
    order: 0;

    > * {
      margin-right: 20px;
      margin-top: 9px;
    }

    > hr {
      flex-basis: 100%;
      height: 2px;
      margin-top: -2px;
      opacity: 0;
      visibility: hidden;
      min-height: unset;
    }
  }

  &-footer {
    order: 2;
    padding: 8px;
    align-items: center;
    justify-content: center;

    > .button + .button {
      margin-left: 8px;
    }
  }

  &-body {
    flex-basis: 100%;
    flex-grow: 2;
    order: 1;
  }

  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    color: $color-black;
    min-height: 28px;

    > h1 {
      @include headline(1);
    }

    > h1 > .textbox > .textbox-control {
      @include headline(1);
      margin: 1px 0;
    }

    &-label {
      @include text-body();
      color: $color-secondary;
      margin-top: 2px;
      margin-bottom: 1px;

      .data-value {
        min-width: 3em;
        display: inline-block;
      }
    }
  }
}

@supports (backdrop-filter: blur(10px)) {
  .section {
    &-header-bg,
    &-footer {
      background: rgba($color-white, 0.8);
      backdrop-filter: blur(10px);
    }

    body.-fullscreen &-header-bg,
    body.-fullscreen &-footer {
      backdrop-filter: none;
    }
  }
}
