@import "../../consts";

.textarea {
  @include clear();
  @include text-control();
  @include transition('box-shadow, background', short);
  border-radius: $br;
  box-shadow: inset 0 0 0 1px $color-quarternary;
  min-height: 36px;
  padding: 8px 14px;
  box-sizing: border-box;
  width: 100%;
  resize: vertical;

  &::placeholder {
    color: $color-secondary;
  }
  
  &:focus {
    box-shadow: inset 0 0 0 1px $color-tertiary;
    background: rgba($color-white, 0.7);
  }

  &[aria-invalid] {
    color: change-color($color-danger, $lightness: 35%);
    box-shadow: inset 0 0 0 1px change-color($color-danger, $lightness: 90%, $saturation: 70%);
    
    &:focus {
      box-shadow: inset 0 0 0 1px change-color($color-danger, $lightness: 80%, $saturation: 70%);
    }

    &::placeholder {
      color: change-color($color-danger, $lightness: 65%, $saturation: 60%);
    }
  }
  
  &:disabled {
    color: $color-secondary;
    background: rgba($color-quarternary, 0.5);
  }
}